import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import produce from "immer";

import { formValidation } from "../../FormValidations/paymentLinkValidations";
import { planValidations } from "../../FormValidations/planValidations";
import { customerValidations } from "../../FormValidations/customerValidations";
import { smartRetrialsValidations } from "../../FormValidations/smartRetrialsValidations";

import currencyAndSymbolUnique from "../../../../../helpers/currencyAndSymbolUnique";
import states from "../../../../../helpers/states-format-id";

import SplitsServices from "../../../../../services/Splits";
import RequesPaymentService from "../../../../../services/RequestPayment";
import BexsServices from "../../../../../services/BexsServices";
import SessionService from "../../../../../services/Session";
import OrderService from "../../../../../services/Order";
import StoreService from "../../../../../services/Store";
import OrderFeesServices from "../../../../../services/OrderFees";
import CustomersServices from "../../../../../services/Customers";

//Store
import { useRequestPaymentLinkStore } from "../../../../../store/requestPaymentLink";
import { useSettings } from "../../../../../store/settings";

//Types
import {
  Plan,
  Currency,
  ClassRoom,
  Product,
  SmartRetrial,
  ProductState,
  AccessUnlimited,
  Split,
  SplitRule,
  Recipient,
  Customer,
} from "../types";

function usePaymentLinkHook({
  newPaymentLink,
  setNewPaymentLink,
  customer,
  setCustomer,
  tax,
  setTax,
  newPlan,
  setNewPlan,
  orderBump,
  upSell,
  crossSell,
}: any) {
  dayjs.extend(customParseFormat);

  const { selectedPlanBackup } = useRequestPaymentLinkStore();
  const { settings } = useSettings();
  const { environment } = useSettings((state) => state.settings);
  
  const { shouldCreateDefaultlLink } = settings;

  const [storeFeesAfterOrder, setStoreFeesAfterOrder] = useState<number[]>([]);

  const [classRooms, setClassRooms] = useState<ClassRoom[]>([]);
  const [splits, setSplits] = useState<Split[]>([
    {
      id: 0,
      storeId: 0,
      percentage: 0,
      fixed: 0,
      expirationDate: "",
      expirationTime: "",
    },
  ]);
  const [topBanner, setTopBanner] = useState<any>([]);
  const [rightBanner, setRightBanner] = useState<any>([]);
  const [topBannerBase64, setTopBannerBase64] = useState("");
  const [rightBannerBase64, setRightBannerBase64] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [selectedSplits, setSelectedSplits] = useState<SplitRule[]>([]);
  const [selectedRecipient, setSelectedRecipient] = useState<Recipient[]>([]);
  const [selectedRecipientIds, setSelectedRecipientIds] = useState<number[]>(
    []
  );
  const [selectedCourses, setSelectedCourses] = useState<Product[]>([]);
  const [selectedCustomerID, setSelectedCustomerID] = useState<string>("");
  const [createCustomerForPaymentLink, setCreateCustomerForPaymentLink] =
    useState<boolean>(false);
    
    const [isCreatingSplit, setIsCreatingSplit] = useState(false);
    
    const [labelButton, setLabelButton] = useState(() => {
      if(shouldCreateDefaultlLink) return "request.payment.generate.payment.link.label";
      return "ui.cta.next";
    });
    
  const [maxSubscriptionCharges, setMaxSubscriptionCharges] = useState("1");

  const [planFrequency, setPlanFrequency] = useState("");

  const [paymentType, setPaymentType] = useState("");

  const [customerCurrency, setCustomerCurrency] = useState("BRL");
  const [customerCurrencySymbol, setCustomerCurrencySymbol] = useState("R$");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessageState, setSuccessMessageState] = useState("");

  const [formErrors, setFormErrors] = useState([]);

  const [selectedUnlimitedAccess, setSelectedUnlimitedAccess] = useState(false);
  const [unlimitedAccessCourse, setUnlimitedAccessCourse] =
    useState<AccessUnlimited>({
      connector: "",
      id: 0,
      images: [""],
      name: "",
      unitPrice: 0,
    });
    
    const [manualDuedateError, setManualDuedateError] = useState("");
    
    const [brValueOrder, setBrValueOrder] = useState("0");
    const [brlTaxAmount, setBrlTaxAmount] = useState("0");
    
    const [productType, setProductType] = useState("");
    
  const [daysBeforeGenerateBoleto, setDaysBeforeGenerateBoleto] = useState("1");

  const [iDUrl, setIDUrl] = useState("");

  const [conversionRates, setConversionRates] = useState();

  const [selected, setSelected] = useState("BR");

  const [ipState, setIpState] = useState("");

  const [fxPlanAmount, setFxPlanAmount] = useState(0);

  const [limitBoleto, setLimitBoleto] = useState(0);

  const [progressStep, setProgressStep] = useState(() => {
    if(shouldCreateDefaultlLink) return 2;
    
    return 1;
  });
  
  const [bexsAmount, setBexsAmount] = useState(0);
  
  const [isCreatingANewPlan, setIsCreatingANewPlan] = useState(false);

  const [customizeBoleto, setCustomizeBoleto] = useState(false);
  const [informPaymentLinkDueDatePlan, setInformPaymentLinkDueDatePlan] =
    useState(false);
  const [fixedInstallment, setFixedInstallment] = useState(false);
  const [payLater, setPayLater] = useState(false);

  const [customizePaymentMethods, setCustomizePaymentMethods] = useState(false);

  const [preFillCustomersData, setPreFillCustomersData] = useState(false);
  const [openAmount, setOpenAmount] = useState(false);

  const [informCycle, setInformCycle] = useState(false);
  const [informTrialPeriod, setInformTrialPeriod] = useState(false);
  const [informTax, setInformTax] = useState(false);

  const [applySplitsAfterOrder, setApplySplitsAfterOrder] = useState(false);
  const [defineSplit, setDefineSplit] = useState(false);

  const [includeFeeTransfer, setIncludeFeeTransfer] = useState(false);

  const [smartRetrial, setSmartRetrial] = useState<SmartRetrial>({
    informRetries: false,
    daysBetweenRetries: "0",
    maxRetries: "0",
    errors: [],
  });

  const [collectUserDataInCheckout, setCollectUserDataInCheckout] =
    useState(false);

  const [processing, setProcessing] = useState(false);

  const [boleto, setBoleto] = useState(true);
  const [pix, setPix] = useState(true);
  const [card, setCard] = useState(true);
  const [informRedirectLink, setInformRedirectLink] = useState(false);
  const [informBanners, setInformBanners] = useState(false);
  const [applySmartInstallments, setApplySmartInstallments] = useState(false);

  const [storeHasENotasKEY, setStoreHasENotasKEY] = useState(false);
  
  const [limitInstallments, setLimitInstallments] = useState(false);

  // const [createPlanFirst, setCreatePlanFirst] = useState(true);

  const [applyBumpUpCrossSell, setApplyBumpUpCrossSell] = useState(false);

  const [successCardAndBoletoLinkRequest, setSuccessCardAndBoletoLinkRequest] =
    useState(false);

  const [isForeigner, setIsForeigner] = useState(false);

  const [creatingPlan, setCreatingPlan] = useState(false);

  const [isTrueOrderNumber, setIsTrueOrderNumber] = useState(false);

  const [applyOrderBump, setApplyOrderBump] = useState(false);
  const [applyUpSell, setApplyUpSell] = useState(false);
  const [applyCrossSell, setApplyCrossSell] = useState(false);
  const [showLimitDescError, setShowLimitDescError] = useState(false);

  const [hasExpiration, setHasExpiration] = useState(false);
  const [expirationLink, setExpirationLink] = useState({
    date: "",
    time: "",
  });

  const [lateFee, setLateFee] = useState({
    mode: "",
    amount: "",
    errors: [],
  });
  const [discount, setDiscount] = useState({
    mode: "",
    amount: "",
    errors: [],
  });
  const [interest, setInterest] = useState({
    mode: "",
    amount: "",
    limitDate: "0000-00-00",
    errors: [],
  });

  const [colorsTheme, setColorsTheme] = useState({
    backgroundColor: "",
    color: "",
  });

  const [productPreSelected, setProductPreSelected] = useState<ProductState>();

  const [store, setStore] = useState(() => {
    const profileInfo = localStorage.getItem("@PrimefySidebarInfos");
    const session = localStorage.getItem("session");

    const { logoPath, businessName } = JSON.parse(
      profileInfo ? profileInfo : "{}"
    );
    const { nameStore } = JSON.parse(session ? session : "{}");

    return {
      logoPath: logoPath,
      nameStore: nameStore ? nameStore : "",
    };
  });
  
  const [storeCurrencyInfo, setStoreCurrencyInfo] = useState(() => {
    const currencyInfo = localStorage.getItem("@PrimefyStore");
    
    const { currency, currencySymbol } = JSON.parse(
      currencyInfo ? currencyInfo : "{}"
    );

    return {
      currency,
      currencySymbol,
    };
  });

  const [foreignerStoreCurrencySelect, setForeignerStoreCurrencySelect] =
    useState<Currency[]>();
    
    const history = useHistory();
    const location = useLocation<ProductState>();
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    const roles = [ "ViewOnly", "Agent"];

    const [accessLevel, setAccessLevel] = useState<number | undefined>(() => {
      const session = JSON.parse(localStorage.getItem("session") || "{}");
      if (session.role) {
        return roles.indexOf(session.role.split(".")[1]);
      }

      return undefined;
    });
    
  useEffect(() => {
    if(selectedCustomerID !== "") {
      const session = JSON.parse(localStorage.getItem("session") || "{}");
      
      CustomersServices.getCustomerDetails(selectedCustomerID, session.storeId).then((response) => {
        const responseCustomer = response.data.customer;
        
        const stateID = states.find((state) => state.uf = responseCustomer.address.state)
        
        const newCustomer = {
          firstName: responseCustomer.firstName,
          lastName: "",
          businessName: "",
          surname: responseCustomer.surname,
          identificationNumber: responseCustomer.identificationNumber,
          email: responseCustomer.email,
          phone: responseCustomer.phone,
          birthdate: responseCustomer.birthdate.split("T")[0],
          address: {
            street: responseCustomer.address.street,
            number: responseCustomer.address.number,
            zipcode: responseCustomer.address.zipCode,
            reference: responseCustomer.address.reference,
            district: responseCustomer.address.district,
            city: responseCustomer.address.city,
            state: stateID?.value,
            country: 0
          }
        }
        
        setCustomer(newCustomer)
      })
    }
  }, [selectedCustomerID])
  
  useEffect(() => {
    if(createCustomerForPaymentLink) {
      const emptyCustomer = {
        firstName: "",
        lastName: "",
        businessName: "",
        surname: "",
        identificationNumber: "",
        email: "",
        phone: "",
        birthdate: "",
        address: {
          street: "",
          number: "",
          zipcode: "",
          reference: "",
          district: "",
          city: "",
          state: 0,
          country: 0
        }
      }
      
      setCustomer(emptyCustomer)
    }
    
  }, [createCustomerForPaymentLink]);

  useEffect(() => {
    if (topBanner.length === 1) {
      setTimeout(() => {
        setTopBannerBase64(topBanner[0].getFileEncodeDataURL());
      }, 3500);
    }
  }, [topBanner]);

  useEffect(() => {
    setMaxSubscriptionCharges(selectedPlanBackup.maxCharges.toString());
  }, [selectedPlanBackup]);

  useEffect(() => {
    if (paymentType === "recurring") {
      handleBoletoInstallments("1");
      handleInstallments("1");
    }
  }, [paymentType]);

  useEffect(() => {
    if (location.state) {
      setProductPreSelected(location.state);
    }
  }, []);

  useEffect(() => {
    if (rightBanner.length === 1) {
      setTimeout(() => {
        setRightBannerBase64(rightBanner[0].getFileEncodeDataURL());
      }, 3500);
    }
  }, [rightBanner]);

  useEffect(() => {
    if (selectedProducts.length > 0 || selectedCourses.length > 0) {
      setOpenAmount(false);
    }
  }, [selectedProducts, selectedCourses]);

  useEffect(() => {
    if (isForeigner) {
      let allowedCurrencies = [
        { id: 0, currency: "BRL", symbol: "R$" },
        {
          id: 1,
          currency: storeCurrencyInfo.currency,
          symbol: storeCurrencyInfo.currencySymbol,
        },
      ];

      setForeignerStoreCurrencySelect(allowedCurrencies);
    }
  }, [isForeigner]);

  const [isPtbr, setIsPtbr] = useState(() => {
    if (navigator.language === "pt-BR") {
      return true;
    }

    return false;
  });

  const [informInvoice, setInformInvoice] = useState(() => {
    if (storeHasENotasKEY) {
      return true;
    }

    return false;
  });

  const [cardInstallmentsAmount, setCardInstallmentsAmount] = useState<any>([]);
  const [boletoInstallmentsAmount, setBoletoInstallmentsAmount] = useState<
    number[]
  >([]);

  const [plans, setPlans] = useState<Plan[]>([]);

  const eNotasInputRef = useRef<HTMLInputElement>(null);

  const intl = useIntl();

  useEffect(() => {
    if(!shouldCreateDefaultlLink) {
      if (paymentType === "recurring" && progressStep === 2 && creatingPlan) {
        setLabelButton("ui.cta.next");
      }
  
      if (progressStep === 3) {
        setLabelButton("request.payment.generate.payment.link.label");
      } else {
        setLabelButton("ui.cta.next");
      }
    }
  }, [progressStep, paymentType, shouldCreateDefaultlLink]);

  useEffect(() => {
    if (newPaymentLink.whenSend !== "") {
      setNewPaymentLink((prevState: any) => {
        return {
          ...prevState,
          sendToCustomer: "true",
        };
      });
    }

    if (newPaymentLink.whenSend === "2") {
      setNewPaymentLink((prevState: any) => {
        return {
          ...prevState,
          sendToCustomer: "",
          warrantyDays: "",
        };
      });
    }
  }, [newPaymentLink.whenSend]);

  async function getExchange() {
    const response = await axios
      .get(
        `https://v6.exchangerate-api.com/v6/ee1a514ea6758da13f9d976a/latest/BRL`
      )
      .then((response) => {
        setConversionRates(response.data.conversion_rates);
      });
  }

  useEffect(() => {
    if (storeHasENotasKEY) {
      setInformInvoice(true);

      if (eNotasInputRef.current) {
        eNotasInputRef.current.checked = true;
      }
    }
  }, []);

  useEffect(() => {
    if (!informInvoice) {
      setNewPaymentLink((prevState: any) => {
        return {
          ...prevState,
          whenSend: "2",
          sendToCustomer: "false",
        };
      });
    }
  }, [informInvoice]);

  useEffect(() => {
    StoreService.get().then((response) => {
      setStore((prevState) => {
        return {
          ...prevState,
          nameStore: response.data.socialName,
        };
      });

      if (response.data.isInterestFeeApplicable) {
        setStoreFeesAfterOrder([
          0, 4.4065, 5.9, 7.4129, 8.9355, 10.4774, 12.0339, 13.6, 15.171,
          16.7742, 18.3742, 20.0,
        ]);
      }

      setIsForeigner(response.data.isForeigner);

      if (response.data.isForeigner) {
        setBoleto(false);
        let currency = response.data.fxMerchant.account.currency.toLowerCase();
        let productType = response.data.fxMerchant.productType;

        BexsServices.getBexsFXRate(currency, productType)
          .then((responseBexs) => {
            setBexsAmount(responseBexs.data.rate * response.data.amount);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        getExchange();
      }

      setStoreHasENotasKEY(response.data.generateInvoice);
      // setApplySplitsAfterOrder(response.data.applySplitsAfterOrder);

      if (response.data.isForeigner) {
        setProductType(response.data.fxMerchant.productType);
      }
    });
  }, []);

  useEffect(() => {
    RequesPaymentService.requestPlans().then((response) => {
      setPlans(response.data.plans);
    });
  }, []);

  useEffect(() => {
    let zipcodeClean = customer.address.zipcode.replace("-", "");

    if (zipcodeClean.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${zipcodeClean}/json/`)
        .then((response) => {
          setCustomer((prevState: any) => {
            return {
              ...prevState,
              address: {
                ...prevState.address,
                street: response.data.logradouro,
                number: "",
                district: response.data.bairro,
                city: response.data.localidade,
                state: response.data.uf,
                country: "BR",
              },
            };
          });
        });
    }
  }, [customer.address.zipcode]);

  function handleOnBlurDescription() {
    if (newPaymentLink.description.length > 255) {
      setShowLimitDescError(true);
    } else {
      setShowLimitDescError(false);
    }
  }

  function handleDiscount(value: string) {
    setDiscount((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleFee(value: string) {
    setLateFee((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleTicket(value: string) {
    setInterest((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleDiscountFeeTicket(type: string, value: string) {
    if (type === "interest") {
      setInterest((prevState: any) => {
        return {
          ...prevState,
          mode: value,
        };
      });
    } else if (type === "lateFee") {
      setLateFee((prevState: any) => {
        return {
          ...prevState,
          mode: value,
        };
      });
    } else if (type === "discount") {
      setDiscount((prevState: any) => {
        return {
          ...prevState,
          mode: value,
        };
      });
    }
  }

  useEffect(() => {
    StoreService.get().then((response) => {
      setLimitBoleto(response.data.storeSettings.limitBoleto);
    });
  }, []);

  useEffect(() => {
    if (newPaymentLink.name !== "crie-um-plano") {
      setErrorMessage("");
      setManualDuedateError("");
      setSuccessMessageState("");
    }
  }, [newPaymentLink.name]);

  useEffect(() => {
    setCreatingPlan(false);
  }, [paymentType]);

  useEffect(() => {
    if (!card) {
      setNewPaymentLink((prevState: any) => {
        return {
          ...prevState,
          installments: 1,
        };
      });
    }

    if (!boleto) {
      setNewPaymentLink((prevState: any) => {
        return {
          ...prevState,
          boletoInstallments: "1",
        };
      });
    }
  }, [paymentType, card, boleto]);

  let finalNewSplitRules: any = [];
  async function createNewSplits() {
    let globaIndex = 0;
    for (const split of splits) {
      const splitType =
        split.percentage > 0 && split.fixed > 0
          ? "both"
          : split.percentage > 0
          ? "percentage"
          : "fixed";

      const newSplitRule = {
        recipientId: selectedRecipientIds[globaIndex],
        type: splitType,
        disputeLiable: true,
        refundLiable: true,
        percentage: split.percentage,
        fixed: split.fixed,
      };

      const post = await SplitsServices.addSplitRule(newSplitRule)
        .then((response) => {
          finalNewSplitRules.push({
            splitRuleId: response.data.id,
          });
        })
        .catch((err) => {
          console.log(err);
        });

      globaIndex = globaIndex + 1;
    }

    // splits.forEach(async (split, index) => {
    //   const response = await
    // });
  }

  async function paymentLinkGenerator(
    event: React.SyntheticEvent,
    newPlanId?: number
  ) {
    event.preventDefault();

    setProcessing(true);

    const amountWhitoutCurrencySymbol =
      paymentType === "recurring"
        ? newPaymentLink.amount
        : newPaymentLink.amount.replace(customerCurrencySymbol, "");

    const postType = paymentType === "single" ? 0 : 1;

    let postPaymentTypes = [];

    if (card) {
      postPaymentTypes.push(0);
    }

    if (boleto) {
      postPaymentTypes.push(1);
    }

    if (pix) {
      postPaymentTypes.push(3);
    }

    let brDiscountLimitDateFormatted = moment(
      newPaymentLink.discountLimit,
      "DD/MM/YYYY"
    ).toString();
    let discountLimitDateFormatted = moment(
      brDiscountLimitDateFormatted
    ).format("YYYY-MM-DD");

    let isBeforeDuedate = false;
    let formattedManualDuedate = "";

    if (newPaymentLink.dueDate !== "") {
      let brFormatted = moment(newPaymentLink.dueDate, "DD/MM/YYYY").toString();
      formattedManualDuedate = moment(brFormatted).format("YYYY-MM-DD");
      isBeforeDuedate = moment(formattedManualDuedate).isBefore(moment());
    }

    // if (isBeforeDuedate) {
    //   let errors = [];

    //   setProcessing(false);
    //   setManualDuedateError(intl.formatMessage({ id: "request.payment.invalid.boleto.duedate.error.message" }));
    //   errors.push("linkBoletoDuedateInvalid");
    //   setFormErrors(errors);
    // }
    // else if (boleto && parseFloat(amountWhitoutCurrencySymbol) > limitBoleto) {
    //   setErrorMessage(`${intl.formatMessage({ id: "request.payment.limit.boleto.error.message.1" })} ${intl.formatNumber(limitBoleto, { style: 'currency', currency: 'BRL' })}${intl.formatMessage({ id: "request.payment.limit.boleto.error.message.2" })}`);
    //   setProcessing(false);

    // }
    if (
      boleto &&
      parseFloat(amountWhitoutCurrencySymbol) /
        newPaymentLink.boletoInstallments <
        3 &&
      paymentType === "single"
    ) {
      setErrorMessage(
        intl.formatMessage({
          id: "request.payment.min.amount.boleto.error.message",
        })
      );
      setProcessing(false);
    } else {
      const cepClean = customer.address.zipcode.replace("-", "");
      let data = customer.birthdate
        ? moment(customer.birthdate, "DD/MM/YYYY")
        : "";
      let birthDateGenerated =
        data !== "" ? moment(data).format("YYYY-MM-DD") : "";

      let identificationNumberClean = "";

      if (customer.identificationNumber.length === 15) {
        identificationNumberClean = identificationNumberClean
          .slice(0, -1)
          .replace(".", "")
          .replace(".", "")
          .replace("-", "");
      } else {
        identificationNumberClean =
          customer.identificationNumber.length === 14
            ? customer.identificationNumber
                .replace(".", "")
                .replace(".", "")
                .replace("-", "")
            : customer.identificationNumber
                .replace(".", "")
                .replace(".", "")
                .replace("/", "")
                .replace("-", "");
      }

      let finalPlanId = newPlanId
        ? newPlanId
        : paymentType === "recurring"
        ? newPaymentLink.planId
        : undefined;

      let formattedProducts: Product[] = [];
      let reducedProductsWithCourses = {
        products: [] as Product[],
        courses: [] as Product[],
      };
      if (newPaymentLink.products) {
        formattedProducts = newPaymentLink.products.map(
          (productPromise: any, index: number) => {
            return {
              unitPrice: productPromise.unitPrice,
              productName: productPromise.name,
              productId: productPromise.id,
              quantity: productPromise.quantity,
              createdOn: productPromise.unitPricecreatedOn,
              connector: productPromise.connector,
              selectedRoom: "",
              selectedRoomId: 0,
            };
          }
        );

        reducedProductsWithCourses = formattedProducts.reduce(
          (acc, curr) => {
            if (curr.connector) {
              const courses = [...acc.courses];
              courses.push(curr);
              return {
                products: [...acc.products],
                courses: courses,
              };
            }

            const products = [...acc.products];
            products.push(curr);
            return { products: products, courses: [...acc.courses] };
          },
          { products: [] as Product[], courses: [] as Product[] }
        );
      }

      let finalCourses: any | undefined = [];
      if (selectedUnlimitedAccess && unlimitedAccessCourse) {
        finalCourses = [
          {
            connector: { ...unlimitedAccessCourse.connector },
            productId: unlimitedAccessCourse.id,
            productName: unlimitedAccessCourse.name,
            quantity: 1,
            selectedRoom: "",
            selectedRoomId: 0,
            unitPrice: unlimitedAccessCourse?.unitPrice,
          },
        ];
      } else {
        finalCourses =
          paymentType === "recurring" && !creatingPlan
            ? reducedProductsWithCourses?.courses
            : selectedCourses;
      }

      const finalProducts =
        paymentType === "recurring" && !creatingPlan
          ? reducedProductsWithCourses.products
          : selectedProducts;

      let finalSplits: any = [];
      if (selectedSplits && selectedSplits.length > 0) {
        selectedSplits.forEach((split) => {
          finalSplits.push({ splitRuleId: split.id });
        });
      }

      if (selectedRecipientIds.length > 0) {
        const response = await createNewSplits();
      }

      RequesPaymentService.requestPaymentLinkPost({
        productCategory: isForeigner ? productType : undefined,
        includeFeeTransfer: shouldCreateDefaultlLink ? true : includeFeeTransfer,
        type: shouldCreateDefaultlLink ? 0 : postType,
        orderNumber: undefined,
        planId: finalPlanId,
        installments: shouldCreateDefaultlLink ? 12 : newPaymentLink.installments,
        boletoInstallments: newPaymentLink.boletoInstallments,
        amount: openAmount ? 0 : parseFloat(amountWhitoutCurrencySymbol),
        paymentTypes: shouldCreateDefaultlLink ? [0, 3] : postPaymentTypes,
        title: newPaymentLink.title,
        description: newPaymentLink.description,
        customer: preFillCustomersData
          ? {
              firstName:
                customer.identificationNumber.length > 14
                  ? customer.businessName
                  : customer.firstName,
              surname:
                customer.identificationNumber.length > 14
                  ? ""
                  : customer.lastName,
              identificationNumber: identificationNumberClean,
              email: customer.email,
              phone: customer.phone ? customer.phone : null,
              birthdate: selectedCustomerID !== "" ? customer.birthdate : birthDateGenerated,
              address: {
                street: customer.address.street,
                number: customer.address.number,
                zipcode: cepClean,
                reference: customer.address.reference,
                district: customer.address.district,
                city: customer.address.city,
                state: customer.address.state,
                country: "BR",
              },
            }
          : null,
        discount:
          discount.mode !== ""
            ? {
                mode: discount.mode,
                amount:
                  discount.mode === "Fixed"
                    ? parseFloat(discount.amount)
                    : null,
                percentage:
                  discount.mode === "Percentage"
                    ? parseFloat(discount.amount)
                    : null,
                limitDate:
                  discountLimitDateFormatted &&
                  discountLimitDateFormatted !== "Invalid date"
                    ? discountLimitDateFormatted
                    : undefined,
              }
            : null,
        lateFee:
          lateFee.mode !== ""
            ? {
                mode: lateFee.mode,
                amount:
                  lateFee.mode === "Fixed" ? parseFloat(lateFee.amount) : null,
                percentage:
                  lateFee.mode === "Percentage"
                    ? parseFloat(lateFee.amount)
                    : null,
              }
            : null,
        tax:
          tax.mode !== "" && parseFloat(tax.amount) > 0
            ? {
                mode: tax.mode,
                amount: parseFloat(tax.amount),
              }
            : undefined,
        interestOrFine:
          interest.mode !== ""
            ? {
                mode: interest.mode,
                amount:
                  interest.mode === "Daily_Amount"
                    ? parseFloat(interest.amount)
                    : null,
                percentage:
                  interest.mode === "Daily_Percentage" ||
                  interest.mode === "Monthly_Percentage"
                    ? parseFloat(interest.amount)
                    : null,
              }
            : null,
        isUserDataRequired: collectUserDataInCheckout,
        expiresOn:
          expirationLink.date === "" && expirationLink.time === ""
            ? null
            : `${expirationLink.date}T${
                expirationLink.time !== ""
                  ? `${expirationLink.time}:00`
                  : "23:59:59"
              }`,
        daysBeforeGenerateBoleto: boleto
          ? parseInt(daysBeforeGenerateBoleto)
          : null,
        generateAllBoletos: boleto
          ? newPaymentLink.generateAllBoletos
          : undefined,
        dueDate: formattedManualDuedate,
        currency: newPaymentLink.currency,
        redirectUrl: newPaymentLink.redirectUrl,
        metadata: {
          shouldCreateDefaultlLink: shouldCreateDefaultlLink,
          topBanner: topBannerBase64,
          rightBanner: rightBannerBase64,
          openAmount: openAmount,
          applySmartInstallments: applySmartInstallments,
          fixedInstallment: fixedInstallment,
          payLater: payLater,
          splits:
            finalSplits.length > 0
              ? [...finalSplits]
              : finalNewSplitRules.length > 0
              ? [...finalNewSplitRules]
              : undefined,
          products: [...finalProducts, ...finalCourses],
          orderBump: applyOrderBump ? orderBump : undefined,
          upSell: applyUpSell ? upSell : undefined,
          crossSell: applyCrossSell ? crossSell : undefined,
          daysBetweenRetries:
            smartRetrial.daysBetweenRetries !== "0"
              ? smartRetrial.daysBetweenRetries
              : undefined,
          maxRetries:
            smartRetrial.maxRetries !== "0"
              ? smartRetrial.maxRetries
              : undefined
        },
      })
        .then((response) => {
          if ((accessLevel === 0 || accessLevel === 1) && session.businessId === (environment === 'test' ? 110 : 3739)) {
            window.open(`https://pay.primefy.com/${response.data.url}`, '_blank');
            history.push(`/transactions`)
          } else {
            history.push(`/payment-links/${response.data.url}/new`);
          }

          setIDUrl(response.data.id);
          setProcessing(false);
          setSuccessCardAndBoletoLinkRequest(true);
        })
        .catch(() => {
          setSuccessCardAndBoletoLinkRequest(false);
          setProcessing(false);
        });
    }
  }

  function handlePlan(event: React.SyntheticEvent, shouldUpdatePlan: boolean) {
    event.preventDefault();
    setProcessing(true);

    const cleanAmount = newPlan.amount.replace(customerCurrencySymbol, "");

    let postPaymentTypes: number[] = [];

    if (card) {
      postPaymentTypes.push(0);
    }

    if (boleto) {
      postPaymentTypes.push(1);
    }

    if (pix) {
      postPaymentTypes.push(3);
    }

    let products;
    const currentSelectedProducts: any[] = [...selectedProducts];

    if (selectedProducts.length > 0) {
      const reducedProducts = currentSelectedProducts.reduce((acc, curr) => {
        return [...acc, { productId: curr.productId, quantity: curr.quantity }];
      }, []);

      products = reducedProducts;
    }

    // const newGracePeriod = dayjs(newPlan.grace, "DD/MM/YYYY").diff(
    //   dayjs(),
    //   "day"
    // );

    let planToUpdate: Plan | undefined;
    if (shouldUpdatePlan) {
      planToUpdate = plans.find((plan) => plan.id === newPaymentLink.planId);

      const updatedPlan = produce(planToUpdate, (draft) => {
        draft!.gracePeriod = parseInt(newPlan.grace);
        draft!.maxCharges = parseInt(maxSubscriptionCharges);
        draft!.chargeDaysBefore = parseInt(newPaymentLink.chargeDaysBefore);
      });

      planToUpdate = updatedPlan;
    }

    const method =
      shouldUpdatePlan && planToUpdate !== undefined
        ? RequesPaymentService.updatePlan(
            planToUpdate.name,
            planToUpdate.description,
            planToUpdate.frequency,
            planToUpdate.amount,
            planToUpdate.gracePeriod,
            undefined,
            planToUpdate.paymentTypes,
            planToUpdate.maxCharges,
            planToUpdate.chargeDaysBefore!,
            planToUpdate.tax?.amount,
            planToUpdate.tax?.mode,
            true,
            planToUpdate.products,
            newPaymentLink.planId
          )
        : RequesPaymentService.postPlans(
            newPlan.name,
            newPlan.description,
            newPlan.frequency,
            parseFloat(cleanAmount),
            informPaymentLinkDueDatePlan && newPaymentLink.dueDate !== ""
              ? 0
              : newPlan.grace,
            isForeigner ? productType : undefined,
            postPaymentTypes,
            informCycle ? parseInt(maxSubscriptionCharges) : undefined,
            boleto ? newPaymentLink.chargeDaysBefore : 0,
            parseFloat(newPlan.taxAmount),
            newPlan.taxMode,
            true,
            products
          );

    Promise.resolve(method)
      .then((response) => {
        setProcessing(false);
        setSuccessMessageState(
          intl.formatMessage({
            id: "request.payment.create.plan.success.message",
          })
        );

        paymentLinkGenerator(event, response.data.id);

        setCreatingPlan(false);
      })
      .catch((err) => {
        setProcessing(false);
        setErrorMessage(
          intl.formatMessage({
            id: "request.payment.create.plan.error.message",
          })
        );
      });
  }

  function handleTax(name: string, value: string) {
    setTax((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handlePaymentType(type: string) {
    setPaymentType(type);
    setProgressStep(1);
  }

  function handleStep(event: React.SyntheticEvent) {
    event.preventDefault();

    const currentStep = progressStep;

    if (currentStep === 2) {
      setIsCreatingANewPlan(true);
    }

    if (currentStep === 3 || shouldCreateDefaultlLink) {
      handleGenerateSwitch(event);
    } else if (newPaymentLink.planId && newPaymentLink.planId > 0) {
      setProgressStep(3);
    } else {
      setProgressStep(currentStep + 1);
    }
  }

  function handlePreviousStep() {
    const currentStep = progressStep;

    if (
      currentStep === 3 &&
      newPaymentLink.planId &&
      newPaymentLink.planId > 0 &&
      !isCreatingANewPlan
    ) {
      setProgressStep(1);
    } else if (currentStep === 3 && isCreatingANewPlan) {
      setProgressStep(2);
      setCreatingPlan(true);
    } else {
      setProgressStep(currentStep - 1);
    }
  }

  function handleNewGracePeriod(value: string) {
    setNewPlan((prevState: any) => {
      return {
        ...prevState,
        grace: value,
      };
    });
  }

  function handleNewPlanData(name: string, value: string) {
    setNewPlan((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleNewPlanAmount(value: string) {
    setNewPlan((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });

    setNewPaymentLink((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleNewPaymentLinkData(name: string, value: string) {
    setNewPaymentLink((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleNewPaymentLinkDataAmount(value: string) {
    setNewPaymentLink((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleCustomizeBoleto() {
    setCustomizeBoleto(!customizeBoleto);
  }

  function handleCustomizePaymentMethods() {
    setCustomizePaymentMethods(!customizePaymentMethods);
  }

  function handleLimitInstallments() {
    setLimitInstallments(!limitInstallments);
  }

  function handleTaxAmount(value: string) {
    setTax((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleExpiration(name: string, value: string) {
    setExpirationLink((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    let currentStep = progressStep;

    if (creatingPlan) {
      if (
        planValidations({
          newPlan,
          progressStep: currentStep,
          handleErrors: setFormErrors,
        })
      ) {
        handleStep(event);
      }
    } else {
      if (
        formValidation(
          {
            newPaymentLink,
            progressStep: currentStep,
            handleErrors: setFormErrors,
            card,
            boleto,
            pix,
            lateFee,
            discount,
            interest,
            openAmount,
            limitBoleto
          } || showLimitDescError
        )
      ) {
        if (preFillCustomersData && selectedCustomerID === "") {
          if (
            customerValidations({
              customer,
              progressStep: currentStep,
              handleErrors: setFormErrors,
              card,
              boleto,
              pix,
              isOptional: true,
            })
          ) {
            if (currentStep === 3) {
              if (
                smartRetrial.informRetries &&
                !smartRetrialsValidations({ smartRetrial, setSmartRetrial })
              ) {
              } else {
                handleGenerateSwitch(event);
              }
            } else {
              handleStep(event);
            }
          }
        } else {
          if (currentStep === 3) {
            if (
              smartRetrial.informRetries &&
              !smartRetrialsValidations({ smartRetrial, setSmartRetrial })
            ) {
            } else {
              handleGenerateSwitch(event);
            }
          } else {
            handleStep(event);
          }
        }
      }
    }
  }

  function handleGenerateSwitch(event: React.SyntheticEvent) {
    if(shouldCreateDefaultlLink) {
      if(parseFloat(newPaymentLink.amount) < 3) {
        setFormErrors(["linkAmountLess3"] as never[]);
      }else {
        paymentLinkGenerator(event);
      }
    }else {
      if (paymentType === "recurring") {
        const planChargesAndGracePeriod = {
          gracePeriod: parseInt(newPlan.grace),
          maxCharges: parseInt(maxSubscriptionCharges),
          chargeDaysBefore: parseInt(newPaymentLink.chargeDaysBefore),
        };
  
        const shouldUpdatePlan =
          !creatingPlan &&
          JSON.stringify(selectedPlanBackup) !==
            JSON.stringify(planChargesAndGracePeriod);
  
        if (creatingPlan || shouldUpdatePlan) {
          handlePlan(event, shouldUpdatePlan);
        } else {
          paymentLinkGenerator(event);
        }
      } else {
        paymentLinkGenerator(event);
      }
    }
  }

  function handleInvoiceData(name: string, value: string) {
    setNewPaymentLink((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  useEffect(() => {
    if (!boleto) {
      setCustomizeBoleto(false);
    }
  }, [boleto]);

  useEffect(() => {
    if (creatingPlan) {
      setProgressStep(2);
    }
  }, [creatingPlan]);

  useEffect(() => {
    let filteredCountry = currencyAndSymbolUnique.filter((currency) => {
      if (currency.currency === customerCurrency) {
        return currency.currency;
      }
    });

    localStorage.setItem(
      "@PrimefyDashboard-currency",
      JSON.stringify({
        currency: customerCurrency,
      })
    );

    setCustomerCurrencySymbol(
      filteredCountry.length > 0 ? filteredCountry[0].symbol : "US$"
    );

    if (conversionRates) {
      setNewPaymentLink((prevState: any) => {
        return {
          ...prevState,
          amount: (
            (parseFloat(brValueOrder) +
              (customerCurrency !== "BRL"
                ? parseFloat(brValueOrder) * 0.496
                : 0)) *
            conversionRates[
              filteredCountry.length > 0 ? customerCurrency : "USD"
            ]
          ).toString(),
        };
      });

      if (tax.mode === "Fixed") {
        const taxAmount = tax.amount;
        handleTax(
          "amount",
          (
            parseFloat(brlTaxAmount) *
            conversionRates[
              filteredCountry.length > 0 ? customerCurrency : "USD"
            ]
          ).toString()
        );
      }
    }

    setNewPaymentLink((prevState: any) => {
      return {
        ...prevState,
        currency: customerCurrency,
      };
    });
  }, [customerCurrency]);

  useEffect(() => {
    if (customerCurrency === "BRL") {
      setBrValueOrder(newPaymentLink.amount);
    }
  }, [newPaymentLink.amount]);

  useEffect(() => {
    if (customerCurrency === "BRL") {
      setBrlTaxAmount(tax.amount);
    }
  }, [tax.amount]);

  function handleInstallments(event: string) {
    let selectedInstallment = parseInt(event);

    setNewPaymentLink((prevState: any) => {
      return {
        ...prevState,
        installments: selectedInstallment,
      };
    });
  }

  function handleBoletoInstallments(event: string) {
    let selectedInstallment = parseInt(event);

    setNewPaymentLink((prevState: any) => {
      return {
        ...prevState,
        boletoInstallments: selectedInstallment,
      };
    });
  }

  useEffect(() => {
    if (card) {
      if (includeFeeTransfer) {
        OrderFeesServices.creditCardFees({
          cardBrand: "",
          amount: parseFloat(newPaymentLink.amount),
          paymentType: "Credit",
        }).then((response) => {
          let amountsWithFees = [];

          for (let index = 0; index <= 11; index++) {
            amountsWithFees.push(
              (
                (response.data[index].amount +
                  parseFloat(newPaymentLink.amount)) /
                (index + 1)
              ).toFixed(2)
            );
          }

          setCardInstallmentsAmount(amountsWithFees);
        });
      } else {
        let amountsWithoutFees = [];
        let addictionalFee = 0;

        for (let index = 0; index <= 11; index++) {
          if (storeFeesAfterOrder.length > 0) {
            addictionalFee =
              (parseFloat(newPaymentLink.amount) * storeFeesAfterOrder[index]) /
              100;
          }
          amountsWithoutFees.push(
            selected === "BR"
              ? (
                  (parseFloat(newPaymentLink.amount) + addictionalFee) /
                  (index + 1)
                ).toFixed(2)
              : parseFloat(newPaymentLink.amount) / (index + 1)
          );
        }

        setCardInstallmentsAmount(amountsWithoutFees);
      }
    }
  }, [includeFeeTransfer, newPaymentLink.amount]);

  useEffect(() => {
    if (boleto) {
      if (includeFeeTransfer) {
        OrderFeesServices.boletoFees({
          amount: parseFloat(newPaymentLink.amount),
          paymentType: "Boleto",
        }).then((response) => {
          if (response.data.length > 0) {
            let fee = response.data[0].amount;
            let amountsWithFees = [];

            if (newPaymentLink.boletoInstallments > 1) {
              for (let index = 0; index <= 11; index++) {
                amountsWithFees.push(
                  parseFloat(
                    (
                      parseFloat(newPaymentLink.amount) / (index + 1) +
                      fee
                    ).toFixed(2)
                  )
                );
              }
            } else {
              amountsWithFees.push(
                parseFloat((parseFloat(newPaymentLink.amount) + fee).toFixed(2))
              );
            }

            setBoletoInstallmentsAmount(amountsWithFees);
          }
        });
      } else {
        let amountsWithoutFees = [];

        for (let index = 0; index <= 11; index++) {
          amountsWithoutFees.push(
            parseFloat(
              (parseFloat(newPaymentLink.amount) / (index + 1)).toFixed(2)
            )
          );
        }

        setBoletoInstallmentsAmount(amountsWithoutFees);
      }
    }
  }, [includeFeeTransfer, boleto, newPaymentLink.amount]);

  function handleSetGenerateAllBoleto(name: string, value: string) {
    setNewPaymentLink((prevState: any) => {
      return {
        ...prevState,
        [name]: value === "true" ? true : false,
      };
    });
  }

  function handleProductQuantity(id: number, value: string) {
    let currentProducts = selectedProducts;

    const newProducts = currentProducts.map((product) => {
      if (product.productId === id) {
        return {
          ...product,
          quantity: value === "" ? parseInt("0") : parseInt(value),
        };
      }

      return { ...product };
    });

    setSelectedProducts(newProducts);
  }

  function handleCourseClassRoom(id: number, value: string) {
    let currentCourses = selectedCourses;

    const newCourses = currentCourses.map((product) => {
      if (product.productId === id) {
        const classNameValue = value.split("-")[0];
        const classIdValue = parseInt(value.split("-")[1]);

        return {
          ...product,
          selectedRoom: classNameValue,
          selectedRoomId: classIdValue,
        };
      }

      return { ...product };
    });

    setSelectedCourses(newCourses);
  }

  useEffect(() => {
    const selectedProductsAmount = selectedProducts.reduce((acc, product) => {
      return acc + product.quantity * product.unitPrice;
    }, 0);

    const selectedCoursesAmount = selectedCourses.reduce((acc, course) => {
      return acc + course.quantity * course.unitPrice;
    }, 0);

    const finalAmount = selectedCoursesAmount + selectedProductsAmount;

    handleNewPaymentLinkDataAmount(finalAmount.toString());
  }, [selectedProducts, selectedCourses]);

  function handleNewPlanAndLinkAmount(value: string) {
    handleNewPaymentLinkDataAmount(value);
    handleNewPlanAmount(value);
  }

  function handleSmartRetrials(name: string, value: string) {
    setSmartRetrial((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleSplit(
    index: number,
    id: number,
    nameProp: string,
    value: any
  ) {
    let currentSplits = splits;

    const editedSplit = currentSplits.find((split) => split.id === id);

    if (editedSplit) {
      const newSplit = {
        ...editedSplit,
        [nameProp]: !["expirationDate", "expirationTime"].includes(nameProp)
          ? parseFloat(value)
          : value,
      };

      currentSplits[index] = newSplit;

      setSplits(currentSplits);
    }
  }

  return {
    progressStep,
    handlePreviousStep,
    creatingPlan,
    handlePaymentType,
    paymentType,
    setCreatingPlan,
    handleSubmit,
    formErrors,
    setSelectedProducts,
    handleNewPlanData,
    handleNewPlanAndLinkAmount,
    productPreSelected,
    setProductPreSelected,
    selectedProducts,
    selectedCustomerID,
    setSelectedCustomerID,
    createCustomerForPaymentLink,
    setCreateCustomerForPaymentLink,
    handleProductQuantity,
    handleNewPaymentLinkData,
    handleNewPlanAmount,
    handleNewPaymentLinkDataAmount,
    isForeigner,
    foreignerStoreCurrencySelect,
    openAmount,
    setOpenAmount,
    customerCurrency,
    setCustomerCurrency,
    customizePaymentMethods,
    card,
    setCard,
    pix,
    setPix,
    boleto,
    setBoleto,
    handleCustomizePaymentMethods,
    limitInstallments,
    handleLimitInstallments,
    handleBoletoInstallments,
    boletoInstallmentsAmount,
    handleInstallments,
    cardInstallmentsAmount,
    includeFeeTransfer,
    applySplitsAfterOrder,
    setFixedInstallment,
    fixedInstallment,
    applySmartInstallments,
    setApplySmartInstallments,
    payLater,
    setPayLater,
    customizeBoleto,
    handleCustomizeBoleto,
    discount,
    handleDiscountFeeTicket,
    lateFee,
    handleFee,
    handleDiscount,
    manualDuedateError,
    interest,
    isPtbr,
    handleTicket,
    handleSetGenerateAllBoleto,
    maxSubscriptionCharges,
    collectUserDataInCheckout,
    preFillCustomersData,
    storeHasENotasKEY,
    informInvoice,
    hasExpiration,
    handleInvoiceData,
    eNotasInputRef,
    informTrialPeriod,
    setCollectUserDataInCheckout,
    setPreFillCustomersData,
    setInformInvoice,
    informTax,
    handleTax,
    handleTaxAmount,
    informCycle,
    setInformCycle,
    setMaxSubscriptionCharges,
    setIncludeFeeTransfer,
    expirationLink,
    handleExpiration,
    setInformTrialPeriod,
    handleNewGracePeriod,
    setHasExpiration,
    informRedirectLink,
    setInformRedirectLink,
    informBanners,
    setInformBanners,
    topBanner,
    setTopBanner,
    rightBanner,
    setRightBanner,
    labelButton,
    processing,
    store,
    bexsAmount,
    conversionRates,
    applyBumpUpCrossSell,
    setApplyBumpUpCrossSell,

    applyOrderBump,
    setApplyOrderBump,
    applyUpSell,
    setApplyUpSell,
    applyCrossSell,
    setApplyCrossSell,
    showLimitDescError,
    handleOnBlurDescription,
    handleSmartRetrials,
    smartRetrial,
    setSmartRetrial,

    storeFeesAfterOrder,

    selectedCourses,
    setSelectedCourses,
    handleCourseClassRoom,
    classRooms,
    setClassRooms,
    selectedUnlimitedAccess,
    setSelectedUnlimitedAccess,
    setUnlimitedAccessCourse,
    defineSplit,
    setDefineSplit,
    splits,
    handleSplit,
    setSplits,
    selectedSplits,
    setSelectedSplits,
    isCreatingSplit,
    setIsCreatingSplit,
    selectedRecipient,
    setSelectedRecipient,
    selectedRecipientIds,
    setSelectedRecipientIds,

    //TODO: eliminar esse monte de state
    informPaymentLinkDueDatePlan,
    setInformPaymentLinkDueDatePlan,
    
    shouldCreateDefaultlLink,
    limitBoleto
  };
}

export default usePaymentLinkHook;
