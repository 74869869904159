import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";

//Srvices
import SessionService from "services/Session";
import StoreServices from "services/Store";
import AdminServices from "services/Admin";
import WebhooksServices from "services/Webhooks";

//Components
import { LogoLogin } from "components/Layout/Index";
import Buttons from "components/Forms/Buttons";
import Input from "components/Forms/InputFloatLabel";
import OnboardingFlow from "./onBoarding";

// Store
import { useAuth } from "../store/auth";
import { useSettings } from "../store/settings";
import { useNuvemshop } from "../store/nuvemshop";

//Assets
import eyeIcon from "assets/images/icon-eye.svg";
import hideIcon from "assets/images/icon-hide.svg";

import "assets/css/login/main.css";
import "assets/css/login/util.css";

//Styles
import * as styled from "./styles/login-styles";

interface HistoryState {
  email: string;
  code: string;
}

const Login = () => {
  const setSession = useAuth((state) => state.setSession);
  const { setSettings, settings } = useSettings((state) => state);
  const { setNuvemshopCode } = useNuvemshop((state) => state);

  const { environment } = useSettings((state) => state.settings);

  const [idStep, setIdStep] = useState(() => {
    const { alreadyHasAccount } = JSON.parse(
      localStorage.getItem("@PremeRecurringHasAccount") || "{}"
    );

    if (alreadyHasAccount) {
      return 0;
    } else {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  const [login, setLogin] = useState(true);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [recoverPassEmail, setRecoverPassEmail] = useState(false);
  const [recoverPass, setRecoverPass] = useState(false);
  const [userIsNotARobot, setUserIsNotARobot] = useState(false);
  const [allQueryParams, setAllQueryParams] = useState<{
    [key: string]: string;
  }>({});

  const [inputType, setInputType] = useState("password");
  const [hideShowPassIcon, setHideShowPassIcon] = useState(eyeIcon);

  const intl = useIntl();
  const location = useLocation<HistoryState>();
  let history = useHistory();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    code: "",
  });

  const { state } = location;

  useEffect(() => {
    let allParams = new URLSearchParams(location.search);
    let allFormattedParams = Object.fromEntries(allParams.entries());

    setAllQueryParams(allFormattedParams);
  }, []);

  useEffect(() => {
    if (state && state.email) {
      setFormData((prevState) => {
        return {
          ...prevState,
          email: state.email,
        };
      });
    }
  }, []);

  useEffect(() => {
    const { code } = allQueryParams;

    if (code !== undefined && code !== "") {
      setNuvemshopCode(code);
      WebhooksServices.sendNuvemshopCode(code)
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => console.log(err));
    }
  }, [allQueryParams]);

  const handleEmailChange = (email: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        email: email,
      };
    });
  };

  const handlePasswordChange = (password: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        password: password,
      };
    });
  };

  const handleSubmit = (event: any) => {
    if (userIsNotARobot) {
      event.preventDefault();
      setIsLoginLoading(true);

      const { code } = allQueryParams;
      SessionService.getToken({
        username: formData.email.toLowerCase(),
        password: formData.password,
        code: code,
      })
        .then((response) => {
          const token = response.data.token;
          const storeId = response.data.storeId;
          const isAdmin =
          response.data.businessId === 0 && response.data.storeId === 0;
          
          SessionService.getUserInfos(token).then((responseUser: any) => {
            if (!isAdmin) {
              StoreServices.getDealType(token, storeId).then(
                (responseDealType) => {
                  localStorage.setItem(
                    "session",
                    JSON.stringify({
                      token: token,
                      expiryAt: response.data.expiryAt,
                      storeId: storeId,
                      entityType: response.data.entityType,
                      businessId: response.data.businessId,
                      role: response.data.role,
                      name: response.data.name,
                      storeName: responseUser.data.stores[0].name ? responseUser.data.stores[0].name : '',
                      hasCommissioning:
                        responseDealType.data.dealType === 0 ||
                        responseDealType.data.dealType === 2
                          ? true
                          : false,
                    })
                  );

                  setSettings({ ...settings, status: responseDealType.data.store.status })

                  const storeEnvironment =
                    responseDealType.data.status !== "enabled" &&
                    responseDealType.data.status !== "denied"
                      ? "test"
                      : "prod";
                  const finalIdentificationNumber = responseDealType.data.owner
                    ? responseDealType.data.owner.identificationNumber
                    : responseDealType.data.identificationNumber;

                  const finalFullName = responseDealType.data.owner
                    ? `${responseDealType.data.owner.firstName} ${responseDealType.data.owner.surname}`
                    : responseDealType.data.name;

                  setSettings({
                    ...settings,
                    environment: storeEnvironment,
                    status: responseDealType.data.store.status,
                    identificationNumber: finalIdentificationNumber,
                    fullName: finalFullName,
                    isForeigner: responseDealType.data.isForeigner,
                    shouldCreateDefaultlLink: storeId === 371
                  });

                  setSession({
                    token: token,
                    expiryAt: response.data.expiryAt,
                    storeId: storeId,
                    entityType: response.data.entityType,
                    businessId: response.data.businessId,
                    role: response.data.role,
                    name: response.data.name,
                    hasCommissioning:
                      responseDealType.data.dealType === 0 ||
                      responseDealType.data.dealType === 2
                        ? true
                        : false,
                  });

                  localStorage.setItem(
                    "storeIdSession",
                    JSON.stringify({
                      storeIdSession: response.data.storeId,
                    })
                  );

                  localStorage.setItem(
                    "@PrimefySidebarInfos",
                    JSON.stringify({
                      userName: responseUser.data.user.name,
                      businessName: responseUser.data.stores[0].businessName,
                      logoPath: responseUser.data.stores[0].logoPath,
                      storeId: response.data.storeId,
                      role: response.data.role,
                    })
                  );
                  if ((response.data.role === 'Store.Agent' || response.data.role === 'Store.ViewOnly') && (environment === 'test' ? response.data.businessId === 110 : response.data.businessId === 3739)) {
                    handleRedirectAgentOrViewOnly()
                  } else {
                    handleRedirectAfterLogin();
                  }
                }
              );
            } else {
              AdminServices.listAllStoresLogin(token).then(
                (responseDealType) => {
                  localStorage.setItem(
                    "session",
                    JSON.stringify({
                      token: token,
                      expiryAt: response.data.expiryAt,
                      storeId: storeId,
                      entityType: response.data.entityType,
                      businessId: response.data.businessId,
                      role: response.data.role,
                      name: response.data.name,
                      hasCommissioning:
                        responseDealType.data.dealType === 0 ||
                        responseDealType.data.dealType === 2
                          ? true
                          : false,
                    })
                  );

                  const storeEnvironment =
                    responseDealType.data.status !== "enabled" &&
                    responseDealType.data.status !== "denied"
                      ? "test"
                      : "prod";

                  const finalIdentificationNumber = responseDealType.data.owner
                    ? responseDealType.data.owner.identificationNumber
                    : responseDealType.data.identificationNumber;

                  const finalFullName = responseDealType.data.owner
                    ? `${responseDealType.data.owner.firstName} ${responseDealType.data.owner.surname}`
                    : responseDealType.data.name;
                  
                    setSettings({
                    ...settings,
                    environment: storeEnvironment,
                    // status: responseDealType.data.stores,
                    identificationNumber: finalIdentificationNumber,
                    fullName: finalFullName,
                    isForeigner: responseDealType.data.isForeigner,
                    shouldCreateDefaultlLink: storeId === 371
                  });
                  setSession({
                    token: token,
                    expiryAt: response.data.expiryAt,
                    storeId: storeId,
                    entityType: response.data.entityType,
                    businessId: response.data.businessId,
                    role: response.data.role,
                    name: response.data.name,
                    hasCommissioning:
                      responseDealType.data.dealType === 0 ||
                      responseDealType.data.dealType === 2
                        ? true
                        : false,
                  });

                  localStorage.setItem(
                    "storeIdSession",
                    JSON.stringify({
                      storeIdSession: response.data.storeId,
                    })
                  );

                  localStorage.setItem(
                    "@PrimefySidebarInfos",
                    JSON.stringify({
                      userName: responseUser.data.user.name,
                      businessName: "Admin account",
                      logoPath: "",
                      storeId: response.data.storeId,
                      role: response.data.role,
                    })
                  );

                  if (response.data.role === 'Agent' || response.data.role === 'ViewOnly') {
                    handleRedirectAgentOrViewOnly()
                  } else {
                    handleRedirectAfterLogin();
                  }
                }
              );
            }
          });
        })
        .catch((error) => {
          setIsLoginLoading(false);

          switch (error.request.status) {
            case 400:
            case 404:
              alert(
                intl.formatMessage({ id: "login.invalid.username.or.password" })
              );
              break;
            default:
              alert(intl.formatMessage({ id: "error.unknown.error" }));
              break;
          }
        });
    }
  };

  function handleRedirectAfterLogin() {
    const tempOrigin =
      sessionStorage.getItem("@Primefy:temp:origin") || undefined;

    if (!tempOrigin) return history.push("/");

    history.push(tempOrigin.replace(/"|^\//g, ""));
    sessionStorage.removeItem("@Primefy:temp:origin");
  }

  function handleRedirectAgentOrViewOnly() {
    const tempOrigin =
      sessionStorage.getItem("@Primefy:temp:origin") || undefined;

    if (!tempOrigin) return history.push("/transactions");

    history.push(tempOrigin.replace(/"|^\//g, ""));
    sessionStorage.removeItem("@Primefy:temp:origin");
  }

  function toggleShowPassword() {
    if (inputType === "password") {
      setInputType("text");
      setHideShowPassIcon(hideIcon);
    } else {
      setHideShowPassIcon(eyeIcon);
      setInputType("password");
    }
  }

  function onChange(value: any) {
    SessionService.getGoogleRecaptchaValidation(value)
      .then((response) => {
        setUserIsNotARobot(response.data.valid);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {(() => {
        switch (idStep) {
          case 0:
            return (
              <styled.LoginSection>
                <styled.Limiter>
                  <styled.ContainerLogin100>
                    <LogoLogin />
                    {login && (
                      <styled.WrapLogin100>
                        <styled.Login100Form
                          className=" validate-form"
                          onSubmit={(e) => handleSubmit(e)}
                        >
                          <styled.Login100FormTitle className="p-b-26">
                            <FormattedMessage id="login.access.dashboard" />
                          </styled.Login100FormTitle>

                          <styled.WrapInput100>
                            <div className="form-item">
                              <h5>Email</h5>

                              <Input
                                required={false}
                                mode="normal"
                                name="email"
                                value={formData.email}
                                placeholder=""
                                handler={handleEmailChange}
                              />
                            </div>

                            <div className="form-item">
                              <h5>
                                {intl.formatMessage({ id: "login.password" })}
                              </h5>

                              <Input
                                required={false}
                                mode="normal"
                                type={inputType}
                                name="password"
                                value={formData.password}
                                placeholder=""
                                handler={handlePasswordChange}
                                icon={hideShowPassIcon}
                                iconHandler={toggleShowPassword}
                              />
                            </div>
                          </styled.WrapInput100>

                          <styled.RecaptchaContainer>
                            <ReCAPTCHA
                              sitekey="6LfboigjAAAAAI5vAWda8wfNGP3lmS0PGm78_gM3"
                              onChange={(value) => onChange(value)}
                              onExpired={() => setUserIsNotARobot(false)}
                            />
                          </styled.RecaptchaContainer>

                          {!isLoginLoading && (
                            <div className="full-width-button margin-top-24">
                              <Buttons
                                mode="filled"
                                label={intl.formatMessage({
                                  id: "login.button.login",
                                })}
                                handler={() => {}}
                                disable={!userIsNotARobot}
                              />
                            </div>
                          )}

                          {isLoginLoading && (
                            <div className="full-width-button margin-top-24">
                              <Buttons
                                mode="filled-processing"
                                label={intl.formatMessage({
                                  id: "ui.cta.loading",
                                })}
                                handler={() => {}}
                              />
                            </div>
                          )}

                          <styled.RecoverPass>
                            <Link to="/new-password">
                              <FormattedMessage id="login.button.recover.pass" />
                            </Link>
                          </styled.RecoverPass>
                        </styled.Login100Form>
                      </styled.WrapLogin100>
                    )}

                    <styled.CreateAccountCall>
                      <p>
                        <FormattedMessage id="login.textcreate.account.text" />{" "}
                        <Link
                          to={`/signup${
                            allQueryParams.code !== undefined &&
                            allQueryParams.code !== ""
                              ? `?code=${allQueryParams.code}`
                              : ""
                          }`}
                        >
                          <FormattedMessage id="login.textcreate.account.link" />
                        </Link>
                      </p>
                    </styled.CreateAccountCall>
                  </styled.ContainerLogin100>
                </styled.Limiter>
                <div id="dropDownSelect1"></div>
              </styled.LoginSection>
            );

          default:
            return <OnboardingFlow handleStep={setIdStep} idStep={idStep} />;
        }
      })()}
    </>
  );
};

export default Login;
