import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

//Components
import MyLoader from "components/ContentLoader";
import {
  ContentBox,
  LayoutWrapper,
  Pagination,
} from "components/Layout/Index";
import Search from "components/Layout/Search";
import DownloadLoading from "components/DownloadLoading";
import Input from "components/Forms/InputFloatLabel";
import Buttons from "components/Forms/Buttons";

//Services
import SessionService from "services/Session";

//Assets
import searchIcon from "assets/images/global/search-transactions.svg";

//Hooks
import { usePlans } from "./hooks";

import * as indexStyled from "../../styles/index-styles";

//Types
import { Plan } from "./types";

import { useSettings } from "../../store/settings";

function Plans() {
  let history = useHistory();
  let { state } = useLocation();
  const intl = useIntl();

  const session = JSON.parse(localStorage.getItem("session") || "{}");

  const { environment } = useSettings((state) => state.settings);

  const viewOnlyAndAgent = (session.role === "Store.Agent" || session.role === "Store.ViewOnly") && session.businessId === (environment === 'test' ? 110 : 3739);

  if (viewOnlyAndAgent) {
    history.push("/transactions")
  }

  const {
    isSearch,
    searchedCustomer,
    isWaitingDownload,
    percentageDownload,
    showNotFound,
    plans,
    isItemsLoaded,
    pageIndex,
    setPageIndex,
    startShowOrders,
    pageIndexProcessing,
    numberTotalOfPlans,
    handleSearchedCustomer,
    getPlans,
    frequencies,
  } = usePlans();

  if (!state) {
    state = "";
  }

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  return (
    <>
      <LayoutWrapper>
        {isSearch && (
          <Search
            placeholder={intl.formatMessage({ id: "home.search.order" })}
          />
        )}

        <DownloadLoading
          isLoading={isWaitingDownload}
          percentage={percentageDownload}
        />

        {startShowOrders && (
          <indexStyled.RowListWithMarginBottom>
            <ContentBox cssClass="m-top-25 list-transactions">
              <indexStyled.TableList>
                <indexStyled.ContextBoxHeader>
                  <div className="title">
                    <FormattedMessage id="subscriptions.page.subscriptions" />
                  </div>
                  <div className="selects">
                    <Input
                      mode="expand-search"
                      placeholder={`${intl.formatMessage({
                        id: `transactions.search`,
                      })} ${intl.formatMessage({
                        id: `transactions.search.${searchedCustomer.type}`,
                      })}`}
                      value={searchedCustomer.value}
                      handler={handleSearchedCustomer}
                      icon={searchIcon}
                      type="text"
                      searchOptions={["identificationNumber", "email", "id"]}
                      onKeyDown={(e) => (e.keyCode === 13 ? getPlans() : null)}
                      searchFunction={() => getPlans()}
                    />

                    <div>
                      <Buttons
                        mode="filled"
                        label={intl.formatMessage({
                          id: "subscriptions.page.create.subscription",
                        })}
                        handler={() => {
                          history.push(`/plans/create`);
                        }}
                      />
                    </div>
                  </div>
                </indexStyled.ContextBoxHeader>
                <indexStyled.GridHeader>
                  <p>
                    <FormattedMessage id="subscriptions.page.subscription" />
                  </p>
                  <p>
                    <FormattedMessage id="subscriptions.page.description" />
                  </p>
                  <p>
                    <FormattedMessage id="subscriptions.page.price" />
                  </p>

                  <p>
                    <FormattedMessage id="subscriptions.page.frequency" />
                  </p>
                  <p>
                    <FormattedMessage id="subscriptions.page.grace.period" />
                  </p>
                  <p>
                    <FormattedMessage id="subscriptions.page.max.charges" />
                  </p>
                </indexStyled.GridHeader>

                <indexStyled.GridPlansContainer>
                  <span className="transactions-list-table">
                    {showNotFound ? (
                      <h3>{intl.formatMessage({ id: "items.not.found" })}</h3>
                    ) : null}

                    {isItemsLoaded &&
                    plans?.plans?.length &&
                    plans?.plans?.length > 0
                      ? plans?.plans.map((plan: Plan, index) => {
                          if (plan.isVisible) {
                            return (
                              <div
                                className={`grid ${
                                  index === plans?.plans.length - 1
                                    ? "last-item"
                                    : ""
                                }`}
                                onClick={() =>
                                  history.push(
                                    `/plans/${plan.storeId}/${plan.id}/edit`
                                  )
                                }
                                key={plan.id}
                              >
                                <p>{`${plan.name}`}</p>
                                <p>{plan.description}</p>
                                <p>
                                  {intl.formatNumber(plan.amount, {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </p>
                                <p>
                                  {intl.formatMessage({
                                    id: `request.payment.plan.${
                                      frequencies[plan.frequency]
                                    }`,
                                  })}
                                </p>
                                <p>{plan.gracePeriod}</p>
                                <p>
                                  {!plan.maxCharges
                                    ? `${intl.formatMessage({
                                        id: "subscriptions.page.max.charges.until.cancel",
                                      })}`
                                    : plan.maxCharges}
                                </p>
                              </div>
                            );
                          }
                        })
                      : null}

                    {!isItemsLoaded ? <MyLoader /> : null} 
                  </span>
                </indexStyled.GridPlansContainer>
              </indexStyled.TableList>
              <indexStyled.PaginationWrapper>
                <Pagination
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  disableNextButton={
                    plans?.plans?.length && plans?.plans?.length < 100
                      ? true
                      : false
                  }
                  pageIndexProcessing={pageIndexProcessing}
                  totalOrder={numberTotalOfPlans}
                  maxNumberOrderPerRequest={100}
                />
              </indexStyled.PaginationWrapper>
            </ContentBox>
          </indexStyled.RowListWithMarginBottom>
        )}
      </LayoutWrapper>
    </>
  );
}

export default Plans;
