import React from 'react';
import { useHistory } from 'react-router-dom';

//Components
import { LayoutWrapper } from 'components/Layout/Index';
import CreateManualPaymentForms from '../components/Layout/RequestPayment/ManualPayment';

//Services
import SessionService from 'services/Session';

//Styles
import * as styled from './styles/request-payment-styles';

import { useSettings } from "../store/settings";

function CreateManualPayment() {
  const history = useHistory();
  const session = JSON.parse(localStorage.getItem("session") || "{}");
  const { environment } = useSettings((state) => state.settings);

  const viewOnlyAndAgent = (session.role === "Store.Agent" || session.role === "Store.ViewOnly") && session.businessId === (environment === 'test' ? 110 : 3739);

  if (viewOnlyAndAgent) {
    history.push("/transactions")
  }

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }
  
  return (
    <>
      <LayoutWrapper>
        <styled.Container>
          <CreateManualPaymentForms />
        </styled.Container>
      </LayoutWrapper>
    </>
  )
}

export default CreateManualPayment;
